import React from 'react';

const StoneAgent: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_47_1084)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7 24L7 11L9 11L9 24L7 24Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24L8 24L8 22L16 22L16 24Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 24L15 11L17 11L17 24L15 24Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.55454 10C6.92084 9.67696 7.39267 9.49912 7.88104 9.5C7.88154 9.5 7.88204 9.50001 7.88254 9.50001L7.87998 10.5V9.5H7.88104L16.1197 9.5C16.609 9.49993 17.0817 9.67924 17.4477 10.004L21.0652 13.2195C21.4051 13.5215 21.632 13.9304 21.7083 14.3787C21.7847 14.827 21.706 15.2879 21.4852 15.6855L18.8885 20.3598L17.1402 19.3885L19.7367 14.7145L16.1203 11.5L16.784 10.752L16.1203 11.4987V11.5H7.87998L7.87742 11.5L7.87998 11.4977L7.21599 10.75L7.87742 11.5L4.26472 14.7146L6.85971 19.3887L5.11124 20.3597L2.51574 15.686C2.29497 15.2884 2.21626 14.8275 2.29261 14.3792C2.36893 13.9312 2.59558 13.5224 2.93524 13.2204L6.55454 10Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C10.6193 2 9.5 3.11929 9.5 4.5C9.5 5.88071 10.6193 7 12 7C13.3807 7 14.5 5.88071 14.5 4.5C14.5 3.11929 13.3807 2 12 2ZM7.5 4.5C7.5 2.01472 9.51472 0 12 0C14.4853 0 16.5 2.01472 16.5 4.5C16.5 6.98528 14.4853 9 12 9C9.51472 9 7.5 6.98528 7.5 4.5Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_1084">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoneAgent;
